<template>
  <div
    class="dropdown"
    :class="{ 'is-active': isActive }"
  >
    <div class="dropdown-trigger">
      <button
        class="button is-link"
        aria-haspopup="true"
        aria-controls="dropdown-menu"
        @click="toggle"
      >
        <span>{{ caption }}</span>

        <span class="icon is-small">
          <i
            class="fas fa-angle-down"
            aria-hidden="true"
          ></i>
        </span>
      </button>
    </div>

    <div
      class="dropdown-menu"
      id="dropdown-menu"
      role="menu"
    >
      <div class="dropdown-content">
        <div
          v-for="(item, idx) in items"
          :key="idx"
          class="dropdown-item"
        >
          <label class="checkbox">
            <input
              type="checkbox"
              :disabled="!available.has(item.id)"
              :checked="isSelected(item.id)"
              @change="onSelect(item.id)"
            >
            {{ item.name }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectParam',

  props: {
    list: {
      type: String,
      required: true,
    },

    caption: {
      type: String,
      required: true,
    },

    items: {
      type: Array,
      required: true,
    },

    selected: {
      type: Array,
      default: () => [],
    },

    available: {
      type: Set,
      required: true,
    },
  },

  data() {
    return {
      isActive: false,
    };
  },

  methods: {
    toggle() {
      this.isActive = !this.isActive;
    },

    onSelect(id) {
      this.$emit('selected', { id, list: this.list });
      this.toggle();
    },

    isSelected(id) {
      return this.selected.includes(id);
    },
  },
};
</script>

<style lang="scss" scoped>
.button.is-link:focus:not(:active), .button.is-link.is-focused:not(:active) {
  box-shadow: 0 0 0 0.125em #001ec2;
}

.dropdown-item {
  min-width: 500px;
}

label {
  word-break: break-word;
}

.dropdown-item {
  max-width: 92vw;
  min-width: 92vw;
}

@media only screen and (max-width: 768px) {
  .button {
    font-size: 0.75rem;
  }
}
</style>
