<template>
  <div id="skipLink">
    <div class="container">
      <p class="has-text-weight-bold is-size-7">
        <a href="#contentWrapper">Skip to content</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SkipLink',
};
</script>
